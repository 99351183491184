import { InfoCard } from 'src/components';
import './ToplineTable.scss';

type tToplineTableGridItem = {
    title?: string;
    value?: string;
    isBig?: boolean;
    isHighlighted?: boolean;
};

export const ToplineTable = ({ gridItems }: { gridItems: tToplineTableGridItem[] }) => {
    return (
        <div className="tabbed-general-stats__topline-table">
            <div className="tabbed-general-stats__grid tabbed-general-stats__grid--nine container">
                {gridItems.map(item => (
                    <div
                        key={item.title}
                        className={`tabbed-general-stats__grid_item ${
                            item.isHighlighted ? 'highlight-grid-item' : ''
                        } ${item.isBig ? 'tabbed-general-stats__grid_item--big' : ''}`}
                    >
                        <InfoCard title={item.title ?? ''} text={item.value ?? '-'} />
                    </div>
                ))}
            </div>
        </div>
    );
};
