import { useState } from 'react';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import CloudscapeModal from '@cloudscape-design/components/modal';
import Container from '@cloudscape-design/components/container';
import FileUpload, { FileUploadProps } from '@cloudscape-design/components/file-upload';
import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import SpaceBetween from '@cloudscape-design/components/space-between';

import { useAppDispatch } from 'src/hooks';
import { uploadFile } from 'src/pages/PropertyLedger/api';
import {
    MessageType,
    UserAccounts,
    dismissMessage,
    displayMessage,
    getUserUploadedPropertyDocumentsAsync,
} from 'src/store';

import './FileUploadModal.scss';

// Max file size is 20 MB
const MAX_FILE_SIZE = 20 * 1024 * 1024;
// Max file name length is 255 characters
export const MAX_FILENAME_LENGTH = 255;
// Display FlashBar alerts for 5 s
export const MODAL_ALERT_DURATION = 5;

export const FileUploadModal = ({
    visible,
    onDismiss,
    propertyId,
    user,
}: {
    visible: boolean;
    onDismiss: () => void;
    propertyId: string;
    user?: UserAccounts;
}) => {
    const dispatch = useAppDispatch();
    const [files, setFiles] = useState<File[]>([]);
    const [updatedFilename, setUpdatedFilename] = useState('');
    const [filenameError, setFilenameError] = useState<string | undefined>(undefined);

    const i18nStrings: FileUploadProps.I18nStrings = {
        uploadButtonText: () => 'Choose file',
        dropzoneText: () => 'Drop file to upload',
        limitShowMore: 'Show more files',
        limitShowFewer: 'Show fewer files',
        removeFileAriaLabel: () => 'remove',
    };

    const uploadDisabled =
        files.length == 0 || files[0].size > MAX_FILE_SIZE || filenameError != null;
    const fileSizeExceeded = files.length > 0 && files[0].size > MAX_FILE_SIZE;
    const errorText = fileSizeExceeded ? 'File size is above 20 MB' : undefined;

    const updateFilename = (newFilename: string) => {
        setUpdatedFilename(newFilename);
        if (newFilename.length > MAX_FILENAME_LENGTH) {
            setFilenameError(`Filename cannot exceed ${MAX_FILENAME_LENGTH} characters`);
        } else {
            setFilenameError(undefined);
        }
    };

    return (
        <CloudscapeModal
            onDismiss={() => onDismiss()}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={uploadDisabled}
                            onClick={async () => {
                                const onDismissAlert = (id: number) => dispatch(dismissMessage(id));
                                if (user != null && files.length > 0) {
                                    try {
                                        dispatch(
                                            displayMessage({
                                                content: `Uploading document '${updatedFilename}'.`,
                                                onDismiss: onDismissAlert,
                                                messageType: MessageType.Info,
                                                durationSeconds: MODAL_ALERT_DURATION,
                                            })
                                        );
                                        onDismiss();
                                        await uploadFile(
                                            user.thirdwebAccount,
                                            propertyId,
                                            files[0],
                                            '',
                                            updatedFilename
                                        );
                                        dispatch(getUserUploadedPropertyDocumentsAsync(propertyId));

                                        dispatch(
                                            displayMessage({
                                                content: `Successfully uploaded document '${updatedFilename}'.`,
                                                onDismiss: onDismissAlert,
                                                messageType: MessageType.Success,
                                                durationSeconds: MODAL_ALERT_DURATION,
                                            })
                                        );
                                    } catch (e) {
                                        dispatch(
                                            displayMessage({
                                                title: `Failed to upload document '${updatedFilename}'.`,
                                                content: `Your request couldn't be processed because of an issue with the server. Try again later.`,
                                                onDismiss: onDismissAlert,
                                                messageType: MessageType.Error,
                                                durationSeconds: MODAL_ALERT_DURATION,
                                            })
                                        );
                                    }
                                }
                            }}
                        >
                            Upload
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Document Upload"
        >
            <Container>
                <FormField label="Property File Upload" constraintText="Select a file to upload">
                    <FileUpload
                        value={files}
                        onChange={changedFiles => {
                            setFiles(changedFiles.detail.value);
                        }}
                        errorText={errorText}
                        constraintText="File size should not exceed 20 MB"
                        i18nStrings={i18nStrings}
                        showFileSize
                        showFileLastModified
                    />
                </FormField>
            </Container>
            <div className="input-file-name">
                <FormField
                    constraintText="Enter a descriptive name for your file. This name will help identify and retrieve the file later."
                    errorText={filenameError}
                    label="File Name - optional"
                >
                    <Input value={updatedFilename} onChange={e => updateFilename(e.detail.value)} />
                </FormField>
            </div>
        </CloudscapeModal>
    );
};
