import { useState } from 'react';
import Alert from '@cloudscape-design/components/alert';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import Cards, { CardsProps } from '@cloudscape-design/components/cards';
import SpaceBetween from '@cloudscape-design/components/space-between';
import TextContent from '@cloudscape-design/components/text-content';

import { Loader } from 'src/components';
import { AccessLevels, PROPERTY_DOCUMENT_UPLOAD_MODULE_ID } from 'src/constants';
import { userHasModulePermission } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { DeleteModal, EditModal, FileUploadModal } from 'src/pages/PropertyLedger/components';
import { tHouseFull, tUserUploadedPropertyDocument } from 'src/types';

import {
    propertyDocumentCardDefinition,
    getUserUploadedDocumentCardDefinition,
} from './DocumentCards';

import './DocumentsTab.scss';

const DOCUMENT_TABS_CARDS_PER_ROW: CardsProps.CardsLayout[] = [
    { cards: 1 },
    { minWidth: 800, cards: 2 },
    { minWidth: 1200, cards: 3 },
    { minWidth: 1600, cards: 4 },
];

interface IProps {
    loading: boolean;
    property?: tHouseFull;
    documents_loading: boolean;
    user_uploaded_property_documents?: tUserUploadedPropertyDocument[];
}

export const DocumentsTab = ({
    loading,
    property,
    documents_loading,
    user_uploaded_property_documents,
}: IProps) => {
    const { user } = useAppSelector(state => state.userReducer);
    const [alertText, setAlertText] = useState<string | undefined>(undefined);

    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemForDeletion, setItemForDeletion] = useState<
        tUserUploadedPropertyDocument | undefined
    >(undefined);

    const [showEditModal, setShowEditModal] = useState(false);
    const [itemForEditing, setItemForEditing] = useState<tUserUploadedPropertyDocument | undefined>(
        undefined
    );

    const showInteractionButton =
        user != null &&
        userHasModulePermission(PROPERTY_DOCUMENT_UPLOAD_MODULE_ID, AccessLevels.EDIT, user);

    if (
        loading ||
        property == null ||
        documents_loading ||
        user_uploaded_property_documents == null
    ) {
        return (
            <div className="property-ledger-loader documents-tab-loader">
                <div className="ledger-loader-wrapper">
                    <div className="ledger-loader">
                        <Loader />
                    </div>
                </div>
            </div>
        );
    }

    const propertyDocuments =
        property.property_documents != null ? property.property_documents : [];
    const documentsCount = user_uploaded_property_documents.length + propertyDocuments.length;

    const deleteDropdownOnClick = (item: tUserUploadedPropertyDocument) => {
        setItemForDeletion(item);
        setShowDeleteModal(true);
    };
    const deleteModalOnDismiss = () => {
        setShowDeleteModal(false);
        setItemForDeletion(undefined);
    };

    const editDropdownOnClick = (item: tUserUploadedPropertyDocument) => {
        setItemForEditing(item);
        setShowEditModal(true);
    };
    const editModalOnDismiss = () => {
        setShowEditModal(false);
        setItemForEditing(undefined);
    };

    const fileUploadModalOnDismiss = () => {
        setShowFileUploadModal(false);
    };

    return (
        <div className="property-ledger-tab-container">
            {alertText != null ? (
                <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header={alertText}
                    onDismiss={() => setAlertText(undefined)}
                    dismissible
                />
            ) : (
                <></>
            )}
            <TextContent>
                <div className="documents-tab-h1">
                    <h1>{`Property Documents (${documentsCount})`}</h1>
                    {showInteractionButton ? (
                        <div className="add-new-document">
                            <Button variant="primary" onClick={() => setShowFileUploadModal(true)}>
                                Add New +
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </TextContent>
            <SpaceBetween direction="vertical" size="m" />
            <div className="documents-tab-user-uploaded-property-documents">
                <TextContent>
                    <div className="documents-tab-h2">
                        <h2>Property Documents</h2>
                    </div>
                </TextContent>
            </div>
            <Cards
                cardDefinition={getUserUploadedDocumentCardDefinition(
                    showInteractionButton,
                    deleteDropdownOnClick,
                    editDropdownOnClick
                )}
                cardsPerRow={DOCUMENT_TABS_CARDS_PER_ROW}
                items={user_uploaded_property_documents}
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        <SpaceBetween size="m">
                            <b>No Property Documents Found</b>
                        </SpaceBetween>
                    </Box>
                }
            />
            <SpaceBetween direction="vertical" size="m" />
            <div className="documents-tab-other-property-documents">
                <TextContent>
                    <h2>Property Documents Uploaded By Balcony</h2>
                </TextContent>
            </div>
            <Cards
                cardDefinition={propertyDocumentCardDefinition}
                cardsPerRow={DOCUMENT_TABS_CARDS_PER_ROW}
                items={property.property_documents != null ? property.property_documents : []}
                empty={
                    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                        <SpaceBetween size="m">
                            <b>No Property Documents Found</b>
                        </SpaceBetween>
                    </Box>
                }
            />
            <DeleteModal
                visible={showDeleteModal}
                onDismiss={deleteModalOnDismiss}
                item={itemForDeletion}
                user={user}
            />
            <EditModal
                visible={showEditModal}
                onDismiss={editModalOnDismiss}
                item={itemForEditing}
                user={user}
            />
            <FileUploadModal
                visible={showFileUploadModal}
                onDismiss={fileUploadModalOnDismiss}
                user={user}
                propertyId={`${property.id}`}
            />
        </div>
    );
};
