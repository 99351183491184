import { Dispatch } from 'redux';
import { tMessagesAddMessage, tMessagesRemoveMessage, tNewMessage } from './types';
import { MESSAGES_ADD_MESSAGE, MESSAGES_REMOVE_MESSAGE } from './action-types';

const addMessage = (message: tNewMessage): tMessagesAddMessage => {
    return {
        type: MESSAGES_ADD_MESSAGE,
        payload: message,
    };
};

const removeMessage = (messageId: number): tMessagesRemoveMessage => {
    return {
        type: MESSAGES_REMOVE_MESSAGE,
        payload: { id: messageId },
    };
};

export const dismissMessage = (messageId: number) => (dispatch: Dispatch) => {
    dispatch(removeMessage(messageId));
};

export const displayMessage = (message: tNewMessage) => (dispatch: Dispatch) => {
    dispatch(addMessage(message));
};
