import { legacy_createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { housesReducer } from './houses';
import { mainReducer } from './main';
import { messagesReducer } from './messages';
import { municipalitiesReducer } from './municipalities';
import { notificationSettingsReducer } from './notificationSettings';
import { propertySalesReducer } from './propertySales';
import { propertyDetailsReducer } from './propertyDetails';
import { rentLevelingReducer } from './rentLeveling';
import { userReducer } from './user';
import { userAdministrationReducer } from './userAdministration';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bindMiddleware = (middleware: any) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const rootReducer = combineReducers({
    housesReducer,
    propertyDetailsReducer,
    mainReducer,
    municipalitiesReducer,
    userReducer,
    propertySalesReducer,
    notificationSettingsReducer,
    userAdministrationReducer,
    messagesReducer,
    rentLevelingReducer,
});

export const store = legacy_createStore(rootReducer, {}, bindMiddleware([thunkMiddleware]));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
