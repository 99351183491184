import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

const CSV_FALLBACK_FILENAME = 'Property_Sales_Missing_CoH.csv';

export const extractCOHCsvFilenameFromHeaders = (
    headers: AxiosResponseHeaders | RawAxiosResponseHeaders
): string => {
    const contentDisposition = headers['content-disposition']
        ? `${headers['content-disposition']}`.split(';')
        : [];
    const contentDispositionKeyValuePairs = contentDisposition
        .filter(isKeyValuePair)
        .map(value => value.split('='));
    const filenames = contentDispositionKeyValuePairs
        .filter(parts => parts[0].trim() === 'filename')
        .map(parts => parts[1].replaceAll('"', ''));
    return filenames.length > 0 ? filenames[0] : CSV_FALLBACK_FILENAME;
};

function isKeyValuePair(str: string): boolean {
    return str.includes('=') && str.indexOf('=') === str.lastIndexOf('=');
}
