import Alert from '@cloudscape-design/components/alert';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import CloudscapeModal from '@cloudscape-design/components/modal';
import SpaceBetween from '@cloudscape-design/components/space-between';

import { useAppDispatch } from 'src/hooks';
import { deleteUserUploadedPropertyDocument } from 'src/pages/PropertyLedger/api';
import { MODAL_ALERT_DURATION } from 'src/pages/PropertyLedger/components/modals';
import {
    MessageType,
    UserAccounts,
    dismissMessage,
    displayMessage,
    getUserUploadedPropertyDocumentsAsync,
} from 'src/store';
import { tUserUploadedPropertyDocument } from 'src/types';

export const DeleteModal = ({
    visible,
    onDismiss,
    user,
    item,
}: {
    visible: boolean;
    onDismiss: () => void;
    user?: UserAccounts;
    item?: tUserUploadedPropertyDocument;
}) => {
    const dispatch = useAppDispatch();
    const filename = item != null ? item.file_name : '';

    const deleteButtonDisabled = item == null || user == null;

    const deleteFunction = async () => {
        const onDismissAlert = (id: number) => dispatch(dismissMessage(id));
        if (item != null && user != null) {
            try {
                dispatch(
                    displayMessage({
                        content: `Deleting document '${item.file_name}'.`,
                        onDismiss: onDismissAlert,
                        messageType: MessageType.Info,
                        durationSeconds: MODAL_ALERT_DURATION,
                    })
                );
                onDismiss();
                await deleteUserUploadedPropertyDocument(
                    user.thirdwebAccount,
                    item.property_id,
                    item.document_id
                );
                dispatch(getUserUploadedPropertyDocumentsAsync(item.property_id));

                dispatch(
                    displayMessage({
                        content: `Successfully deleted document '${item.file_name}'.`,
                        onDismiss: onDismissAlert,
                        messageType: MessageType.Success,
                        durationSeconds: MODAL_ALERT_DURATION,
                    })
                );
            } catch (e) {
                dispatch(
                    displayMessage({
                        title: `Failed to delete document '${item.file_name}'.`,
                        content: `Your request couldn't be processed because of an issue with the server. Try again later.`,
                        onDismiss: onDismissAlert,
                        messageType: MessageType.Error,
                        durationSeconds: MODAL_ALERT_DURATION,
                    })
                );
            }
        }
    };

    return (
        <CloudscapeModal
            onDismiss={() => onDismiss()}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={deleteButtonDisabled}
                            variant="primary"
                            onClick={() => deleteFunction()}
                        >
                            Delete
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Delete '${filename}'`}
        >
            <Alert statusIconAriaLabel="Info">
                Deleting this file will remove it from our database and may make it permanently
                inaccesible on the blockchain.
            </Alert>
        </CloudscapeModal>
    );
};
