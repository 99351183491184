import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import moment from 'moment';
import { NavigateFunction } from 'react-router-dom';

import {
    OwnerAddressCell,
    PropertyClassNameCell,
    PropertyUrlCell,
    tListViewTableColumnDef,
} from 'src/components';
import { DateTimeFormats, dollarFormat, makeDollarFormatter } from 'src/helpers';
import { tPropertySaleSummary } from 'src/types';

const columnHelper = createColumnHelper<tPropertySaleSummary>();

const dollarFormatter = makeDollarFormatter();
export const propertySalesTableColumns: tListViewTableColumnDef<tPropertySaleSummary>[] = [
    columnHelper.accessor('street_address', {
        id: 'street_address',
        header: () => 'Street Address',
        cell: row => {
            return (
                <PropertyUrlCell
                    propertyDetailsUrl={row.row.original.property_details_url}
                    streetAddress={row.getValue()}
                />
            );
        },
    }),
    columnHelper.accessor('sale_date', {
        id: 'sale_date',
        header: () => 'Sale Date',
        cell: row => moment(row.getValue()).utc().format(DateTimeFormats.MMDDYY),
    }),
    columnHelper.accessor('zip_code', {
        id: 'zip_code',
        header: () => 'Zip Code',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('owner', {
        id: 'owner',
        header: () => 'Owner',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('owner_street_address', {
        id: 'owner_street_address',
        header: () => 'Owner Address',
        cell: row => (
            <OwnerAddressCell
                owner_street_address={row.getValue()}
                owner_city={row.row.original.owner_city}
                owner_state={row.row.original.owner_state}
                owner_zip_code={row.row.original.owner_zip_code}
            />
        ),
    }),
    columnHelper.accessor('sale_price', {
        id: 'sale_price',
        header: () => 'Sale Price',
        cell: row => '$' + dollarFormat(row.getValue(), dollarFormatter),
    }),
    columnHelper.accessor('block', {
        id: 'block',
        header: () => 'Block',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('lot', {
        id: 'lot',
        header: () => 'Lot',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('qualifier', {
        id: 'qualifier',
        header: () => 'Qualifier',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('property_class_name', {
        id: 'property_class_name',
        header: () => 'Property Class Name',
        cell: row => <PropertyClassNameCell propertyClassName={row.getValue()} />,
    }),
];
const PropertySalesTableRow = ({
    row,
}: {
    row: Row<tPropertySaleSummary>;
    municipality_slug?: string;
}) => {
    return (
        <tr className="pointer height-medium" key={row.id}>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
        </tr>
    );
};

export const propertySalesTableRowRenderer = (
    row: Row<tPropertySaleSummary>,
    navigate: NavigateFunction,
    municipality_slug: string | undefined
) => (
    <PropertySalesTableRow
        key={`tabbed-property-sales-row-${row.id}`}
        row={row}
        municipality_slug={municipality_slug}
    />
);
