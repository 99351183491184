import { Tooltip } from 'src/components';
import { dotsAfterText } from 'src/helpers';

const PROPERTY_CLASS_LENGTH = 30;
export const PropertyClassNameCell = ({ propertyClassName }: { propertyClassName: string }) => {
    if (propertyClassName == '') {
        return <></>;
    }
    return (
        <Tooltip text={propertyClassName}>
            {dotsAfterText(propertyClassName, PROPERTY_CLASS_LENGTH)}
        </Tooltip>
    );
};
