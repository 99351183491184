import { Toaster } from 'react-hot-toast';

interface IProps {
    className?: string;
}

import './FlashbarContainer.scss';

export const FlashbarContainer = ({ className }: IProps) => {
    const classes = ['flashbar-container'];
    if (className != null) {
        classes.push(className);
    }

    return (
        <div className={classes.join(' ')}>
            <Toaster />
        </div>
    );
};
