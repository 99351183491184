import { Dispatch } from 'redux';
import { Account } from 'thirdweb/wallets';

import { assetApiClient, makeSignedGetRequest } from 'src/api';
import { tSearchPagination } from 'src/types';
import { assembleQueryString } from 'src/store/houses';
import {
    RENT_LEVELING_GET_RENT_LEVELED_PROPERTIES,
    RENT_LEVELING_SET_LOADING,
    RENT_LEVELING_SET_PAGINATION,
    RENT_LEVELING_SET_QUERY,
} from './action-types';
import {
    tGetRentLeveledProperties,
    tRentLeveledProperty,
    tRentLevelingQuery,
    tSetRentLeveledPropertiesLoading,
    tSetRentLeveledPropertiesPagination,
    tSetRentLevelingQuery,
} from './types';

const setRentLeveledProperties = (
    rentLeveledProperties: tRentLeveledProperty[],
    rentLeveledPropertyCount: number,
    rentLeveledUnitAnnualRevenueEstimate: number,
    rentLeveledUnitCount: number,
    loading: boolean,
    query: tRentLevelingQuery,
    paginationUpdate?: tSearchPagination
): tGetRentLeveledProperties => {
    return {
        type: RENT_LEVELING_GET_RENT_LEVELED_PROPERTIES,
        payload: {
            loading,
            rentLeveledProperties,
            rentLeveledPropertyCount,
            rentLeveledUnitAnnualRevenueEstimate,
            rentLeveledUnitCount,
            query,
            pagination: paginationUpdate,
        },
    };
};

const setRentLeveledPropertiesLoading = (loading: boolean): tSetRentLeveledPropertiesLoading => {
    return {
        type: RENT_LEVELING_SET_LOADING,
        payload: {
            loading,
        },
    };
};

export const changeRentLevelingPagination = (
    paginationUpdate: tSearchPagination
): tSetRentLeveledPropertiesPagination => {
    return {
        type: RENT_LEVELING_SET_PAGINATION,
        payload: {
            pagination: paginationUpdate,
        },
    };
};

const changeRentLevelingQuery = (query: tRentLevelingQuery): tSetRentLevelingQuery => {
    return {
        type: RENT_LEVELING_SET_QUERY,
        payload: {
            query,
        },
    };
};

export const getRentLeveledPropertiesAsync =
    (
        municipalityId: string,
        thirdwebAccount: Account,
        query: tRentLevelingQuery,
        paginationUpdate?: tSearchPagination
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(setRentLeveledPropertiesLoading(true));
        dispatch(changeRentLevelingQuery(query));
        try {
            const { data } = await makeSignedGetRequest(
                assetApiClient,
                thirdwebAccount,
                `/rent-leveling/${municipalityId}?${assembleQueryString(query)}`
            );
            dispatch(
                setRentLeveledProperties(
                    data.rent_leveled_properties || [],
                    data.rent_leveled_property_count,
                    data.rent_leveled_unit_annual_revenue_estimate,
                    data.rent_leveled_unit_count,
                    false,
                    query,
                    paginationUpdate
                )
            );
        } catch (e) {
            dispatch(setRentLeveledPropertiesLoading(false));
            console.error(e);
        }
    };
