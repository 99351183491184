import { addressStringFormatter, dotsAfterText } from 'src/helpers';

const OWNER_ADDRESS_LENGTH = 30;

export const OwnerAddressCell = ({
    owner_street_address,
    owner_city,
    owner_state,
    owner_zip_code,
}: {
    owner_street_address: string;
    owner_city: string;
    owner_state: string;
    owner_zip_code: string;
}) => {
    const address = addressStringFormatter(
        owner_street_address,
        owner_city,
        owner_state,
        owner_zip_code
    );
    return <span title={address}>{dotsAfterText(address, OWNER_ADDRESS_LENGTH)}</span>;
};
