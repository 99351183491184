import { Routes, Route, useLocation } from 'react-router-dom';

import {
    NotFound,
    ProductPermissions,
    PropertyLedger,
    PropertySales,
    PropertySalesPageRedirect,
    RentLevelingSummary,
    SearchResult,
    SearchResultsRedirect,
    UserManagement,
    VerifyEmailAddress,
} from './pages';
import { FlashbarContainer, Header } from './components';
import { RENT_LEVELING_MODULE_ID, RedirectRoutes, Routes as R, AccessLevels } from 'src/constants';
import { GoogleAnalyticsLoader, RegisterModalDisplay } from './components';
import { AuthedPage } from './pages/AuthedPage';
import { NotificationSettings } from './pages';
import { userCanViewOrganization, userHasModulePermission, userIsAdmin } from './helpers';

const App = () => {
    const { pathname } = useLocation();

    return (
        <>
            <div className={`app ${pathname !== '/' && 'app--with-padding'}`}>
                <Header />
                <GoogleAnalyticsLoader />
                <FlashbarContainer />
                <Routes>
                    <Route path={R.MAIN} element={<SearchResultsRedirect />} />
                    <Route
                        path={RedirectRoutes.COH_COMPLIANCE}
                        element={<PropertySalesPageRedirect />}
                    />
                    <Route path={R.CITY_RESULT} element={<SearchResult />} />
                    <Route path={R.PROPERTY_LEDGER_RESULT} element={<PropertyLedger />} />
                    <Route
                        path={R.PROPERTY_SALES}
                        element={
                            <AuthedPage fallbackComponent={<PropertySales />}>
                                <PropertySales />
                            </AuthedPage>
                        }
                    />
                    <Route path={R.VERIFY_EMAIL} element={<VerifyEmailAddress />} />
                    <Route path={R.NOT_FOUND} element={<NotFound />} />
                    <Route
                        path={R.NOTIFICATION_SETTINGS}
                        element={
                            <AuthedPage
                                additionalAuthorizeFunction={userCanViewOrganization}
                                additionalAuthorizationFallback={<NotFound />}
                            >
                                <NotificationSettings />
                            </AuthedPage>
                        }
                    />
                    <Route
                        path={R.USER_MANAGEMENT}
                        element={
                            <AuthedPage
                                additionalAuthorizeFunction={userIsAdmin}
                                additionalAuthorizationFallback={<NotFound />}
                            >
                                <UserManagement />
                            </AuthedPage>
                        }
                    />
                    <Route
                        path={R.PRODUCT_PERMISSIONS}
                        element={
                            <AuthedPage
                                additionalAuthorizeFunction={userIsAdmin}
                                additionalAuthorizationFallback={<NotFound />}
                            >
                                <ProductPermissions />
                            </AuthedPage>
                        }
                    />
                    <Route
                        path={R.RENT_LEVELING_SUMMARY}
                        element={
                            <AuthedPage
                                additionalAuthorizeFunction={user =>
                                    userHasModulePermission(
                                        RENT_LEVELING_MODULE_ID,
                                        AccessLevels.READONLY,
                                        user
                                    )
                                }
                                additionalAuthorizationFallback={<NotFound />}
                            >
                                <RentLevelingSummary />
                            </AuthedPage>
                        }
                    />
                </Routes>
                <RegisterModalDisplay />
            </div>
        </>
    );
};

export default App;
