import { createColumnHelper, flexRender, Row } from '@tanstack/react-table';
import { generatePath, NavigateFunction } from 'react-router-dom';
import { OwnerAddressCell, PropertyUrlCell } from 'src/components';
import { Routes } from 'src/constants';
import { tRentLeveledProperty } from 'src/store';

const columnHelper = createColumnHelper<tRentLeveledProperty>();

export const rentLevelingTableColumns = [
    columnHelper.accessor('address', {
        id: 'street_address',
        header: () => 'Street Address',
        cell: row => {
            const url = generatePath(Routes.PROPERTY_LEDGER_RESULT, {
                slug: row.row.original.municipality_id,
                property_id: row.row.original.property_location_id,
            });
            return <PropertyUrlCell propertyDetailsUrl={url} streetAddress={row.getValue()} />;
        },
    }),
    columnHelper.accessor('zip', {
        id: 'zip_code',
        header: () => 'Zip Code',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('owner_name', {
        id: 'owner',
        header: () => 'Owner',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('owner_mailing_address', {
        id: 'owner_address',
        header: () => 'Owner Address',
        cell: row => (
            <OwnerAddressCell
                owner_street_address={row.getValue()}
                owner_city={row.row.original.owner_city}
                owner_state={row.row.original.owner_state}
                owner_zip_code={row.row.original.owner_zip_code}
            />
        ),
    }),
    columnHelper.accessor('property_class_name', {
        id: 'property_class_name',
        header: () => 'Property Class Name',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('number_of_units', {
        id: 'number_of_units',
        header: () => 'Number of Units',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('annual_revenue_estimate', {
        id: 'annual_revenue_estimate',
        header: () => 'Annual Revenue Estimate',
        cell: row => `$${row.getValue()}`,
    }),
];

const RentLevelingTableRow = ({
    row,
}: {
    row: Row<tRentLeveledProperty>;
    municipality_slug?: string;
}) => {
    return (
        <tr className="pointer height-medium" key={row.id}>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
        </tr>
    );
};

export const rentLevelingTableRowRenderer = (
    row: Row<tRentLeveledProperty>,
    navigate: NavigateFunction,
    municipality_slug: string | undefined
) => (
    <RentLevelingTableRow
        key={`tabbed-property-sales-row-${row.id}`}
        row={row}
        municipality_slug={municipality_slug}
    />
);
