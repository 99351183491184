import { LinkButton } from 'src/components/Buttons';

export const PropertyUrlCell = ({
    propertyDetailsUrl,
    streetAddress,
}: {
    propertyDetailsUrl: string;
    streetAddress: string;
}) => {
    return (
        <LinkButton
            text={streetAddress}
            fontSize="large"
            onClick={e => {
                e.stopPropagation();
                window.open(propertyDetailsUrl, '_blank');
            }}
        />
    );
};
