import { Dispatch } from 'redux';

import { assetApiClient } from 'src/api';
import { HOUSES_PAGE_SIZE } from 'src/constants';
import { occupancyStatusToApiQueryParam } from 'src/helpers';
import {
    tAssetSearchData,
    tHouseAssetSmall,
    tHouseSmall,
    tSearchData,
    tSearchPagination,
} from 'src/types';

import {
    CLEAR_ALL_ADDRESSES,
    CLEAR_QUERY,
    GET_ALL_ADDRESSES,
    GET_ALL_HOUSES,
    SET_LOADING,
    SET_PAGINATION,
    SET_QUERY,
} from './action-types';
import { tHousesAction, tMunicipalityAddresses, tMunicipalityAddressSearchResponse } from './types';

export const changeLoading = (loading: boolean) => {
    return {
        type: SET_LOADING,
        payload: {
            loading,
        },
    };
};

export const changeQuery = (query: tSearchData) => {
    return {
        type: SET_QUERY,
        payload: {
            data: query,
        },
    };
};

export const changeHousesPagination = (paginationUpdate: tSearchPagination) => {
    return {
        type: SET_PAGINATION,
        payload: {
            pagination: paginationUpdate,
        },
    };
};

export const changeHousesData = ({
    query,
    data,
    loading,
    count,
    has_more,
    sort,
    pagination,
}: tHousesAction) => {
    return {
        type: GET_ALL_HOUSES,
        payload: {
            query,
            data,
            count,
            has_more,
            sort,
            loading,
            pagination,
        },
    };
};

const getAssetHousesQuery = (query: tSearchData): tAssetSearchData => {
    const q: tAssetSearchData = {
        skip: query.skip != null ? query.skip : 0,
        take: query.take != null ? query.take : HOUSES_PAGE_SIZE,
        block: query.block,
        lot: query.lot,
        qualifier: query.qualifier,
        address: query.address,
        occupied_status: occupancyStatusToApiQueryParam(query.occupancy),
    };
    return q;
};

export const assembleQueryString = (query: { [key: string]: string | number }): string => {
    let queryString = '';
    Object.keys(query).map(objKey => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryString += query[objKey] ? `${objKey}=${query[objKey]}&` : '';
    });
    return queryString;
};

const getAssetHouses = async (query: tSearchData) => {
    const assetQuery = getAssetHousesQuery(query);
    const queryString = assembleQueryString(assetQuery);

    const { data } = await assetApiClient.get(
        `/municipality/${query.municipality_slug}?${queryString}`
    );
    const properties = data.properties != null ? data.properties : [];
    const count = data.count != null ? data.count : undefined;
    const response = {
        data: properties
            .slice(0, HOUSES_PAGE_SIZE)
            .map((house: tHouseAssetSmall) => reshapeHouse(house)),
        has_more: data.length > HOUSES_PAGE_SIZE,
        sort: 'featured',
        count,
    };
    return response;
};

const reshapeHouse = (house: tHouseAssetSmall): tHouseSmall => {
    return {
        address: house.address,
        block: `${house.block}`,
        estate_type: house.property_class_name,
        hash: house.transaction_hash,
        id: house.property_location_id,
        lot: house.lot,
        owner: house.owner_name,
        recent_activity: null,
        slug: house.property_location_id,
        vacant: house.vacant_and_abandoned,
        qualifier: house.qualifier,

        // For consistency with how the tHouseSmall type is used in the Map component, these
        // are provided as a fallback but should not be used.
        lat: 0,
        lng: 0,
    };
};

export const getHousesAsync =
    (query: tSearchData, paginationUpdate?: tSearchPagination) => async (dispatch: Dispatch) => {
        dispatch(changeLoading(true));
        dispatch(changeQuery(query));
        try {
            const data = await getAssetHouses(query);
            dispatch(
                changeHousesData({ query, ...data, loading: false, pagination: paginationUpdate })
            );
        } catch (e) {
            dispatch(changeLoading(false));
            console.log(e);
        }
    };

export const clearQuery = () => {
    return {
        type: CLEAR_QUERY,
    };
};

const clearAllAddresses = () => {
    return {
        type: CLEAR_ALL_ADDRESSES,
    };
};

const setAllAddresses = (addresses: string[]) => {
    return {
        type: GET_ALL_ADDRESSES,
        payload: { data: addresses },
    };
};

const getAllAddresses = async (municipality_slug: string): Promise<tMunicipalityAddresses> => {
    const { data }: { data: tMunicipalityAddressSearchResponse } = await assetApiClient.get(
        `/municipality/addresses/${municipality_slug}?take=10000`
    );
    return {
        count: data.count,
        property_addresses:
            data.property_addresses != null
                ? data.property_addresses.map(property_address => property_address.address)
                : [],
    };
};

export const getAllAddressesAsync = (municipalityId: string) => async (dispatch: Dispatch) => {
    dispatch(clearAllAddresses());
    try {
        const data = await getAllAddresses(municipalityId);
        const uniqueAddresses = Array.from(new Set(data.property_addresses));
        uniqueAddresses.sort();
        dispatch(setAllAddresses(uniqueAddresses));
    } catch (e) {
        console.error(e);
    }
};
