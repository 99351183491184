import Flashbar, { FlashbarProps } from '@cloudscape-design/components/flashbar';
import { Toast } from 'react-hot-toast';

import { MessageType, tMessage } from 'src/store';

const messageTypeToCloudscapeFlashbarType = (messageType: MessageType): FlashbarProps.Type => {
    switch (messageType) {
        case MessageType.Error:
            return 'error';
        case MessageType.Info:
            return 'info';
        case MessageType.Success:
            return 'success';
        case MessageType.Warning:
            return 'warning';
        default:
            return 'info';
    }
};

export const messageToFlashbar = (message: tMessage): FlashbarProps.MessageDefinition => {
    return {
        header: message.title,
        content: message.content,
        dismissible: true,
        type: messageTypeToCloudscapeFlashbarType(message.messageType),
        onDismiss: () => {
            message.onDismiss();
        },
    };
};

export const makeFlashbarToast = (t: Toast, message: tMessage) => {
    const className = t.visible ? 'flashbar-enter container' : 'flashbar-leave container';
    return (
        <div className={className}>
            <Flashbar items={[messageToFlashbar(message)]} />
        </div>
    );
};
