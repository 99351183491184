import { default as CloudscapeModal } from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Input from '@cloudscape-design/components/input';
import FormField from '@cloudscape-design/components/form-field';
import { useEffect, useState } from 'react';

import { useAppDispatch } from 'src/hooks';
import { editUserUploadedPropertyDocument } from 'src/pages/PropertyLedger/api';
import {
    MAX_FILENAME_LENGTH,
    MODAL_ALERT_DURATION,
} from 'src/pages/PropertyLedger/components/modals';
import {
    MessageType,
    UserAccounts,
    dismissMessage,
    displayMessage,
    getUserUploadedPropertyDocumentsAsync,
} from 'src/store';
import { tUserUploadedPropertyDocument } from 'src/types';

export const EditModal = ({
    visible,
    onDismiss,
    user,
    item,
}: {
    visible: boolean;
    onDismiss: () => void;
    user?: UserAccounts;
    item?: tUserUploadedPropertyDocument;
}) => {
    const dispatch = useAppDispatch();
    const [filename, setFilename] = useState(item != null ? item.file_name : '');
    const [filenameError, setFilenameError] = useState<string | undefined>(undefined);

    const filenameForTitle = item != null ? item.file_name : '';
    const saveButtonDisabled = item == null || user == null || filename === '';

    useEffect(() => {
        setFilename(item != null ? item.file_name : '');
    }, [item]);

    const editDocument = async () => {
        const onDismissAlert = (id: number) => dispatch(dismissMessage(id));
        if (item != null && user != null) {
            const updatedFilename = filename === '' ? item.file_name : filename;
            try {
                dispatch(
                    displayMessage({
                        content: `Updating document '${updatedFilename}'.`,
                        onDismiss: onDismissAlert,
                        messageType: MessageType.Info,
                        durationSeconds: MODAL_ALERT_DURATION,
                    })
                );

                onDismiss();

                await editUserUploadedPropertyDocument(
                    user.thirdwebAccount,
                    item.property_id,
                    item.document_id,
                    updatedFilename
                );

                dispatch(getUserUploadedPropertyDocumentsAsync(item.property_id));

                dispatch(
                    displayMessage({
                        content: `Successfully updated document '${updatedFilename}'.`,
                        onDismiss: onDismissAlert,
                        messageType: MessageType.Success,
                        durationSeconds: MODAL_ALERT_DURATION,
                    })
                );
            } catch (e) {
                dispatch(
                    displayMessage({
                        title: `Failed to update document '${updatedFilename}'.`,
                        content: `Your request couldn't be processed because of an issue with the server. Try again later.`,
                        onDismiss: onDismissAlert,
                        messageType: MessageType.Error,
                        durationSeconds: MODAL_ALERT_DURATION,
                    })
                );
            }
        }
    };

    const updateFilename = (newFilename: string) => {
        setFilename(newFilename);
        if (newFilename.length > MAX_FILENAME_LENGTH) {
            setFilenameError(`Filename cannot exceed ${MAX_FILENAME_LENGTH} characters`);
        } else {
            setFilenameError(undefined);
        }
    };

    return (
        <CloudscapeModal
            onDismiss={() => onDismiss()}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveButtonDisabled}
                            variant="primary"
                            onClick={() => editDocument()}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Edit '${filenameForTitle}'`}
        >
            <FormField
                constraintText="Enter a descriptive name for your file. This name will help identify and retrieve the file later."
                errorText={filenameError}
                label="File Name - optional"
            >
                <Input value={filename} onChange={e => updateFilename(e.detail.value)} />
            </FormField>
        </CloudscapeModal>
    );
};
