import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import toast, { ToastOptions } from 'react-hot-toast';

import { MESSAGES_ADD_MESSAGE, MESSAGES_REMOVE_MESSAGE } from './action-types';
import { initialState } from './initialState';
import { MessagesReducer, tMessagesAction } from './types';

import { makeFlashbarToast } from 'src/helpers';

const messagesPersistConfig = {
    key: 'messagesState',
    storage: storage,
    whitelist: [],
};

const messages = (
    state: MessagesReducer = initialState,
    { type, payload }: tMessagesAction
): MessagesReducer => {
    switch (type) {
        case MESSAGES_ADD_MESSAGE: {
            toast.remove();
            const messageId = state.nextMessageId;
            const newMessage = {
                title: payload.title,
                content: payload.content,
                action: payload.action,
                id: messageId,
                messageType: payload.messageType,
                onDismiss: () => {
                    payload.onDismiss(messageId);
                },
            };
            if (payload.durationSeconds != null) {
                setTimeout(() => payload.onDismiss(messageId), payload.durationSeconds * 1000);
            }

            const nextMessageId = state.nextMessageId + 1;

            const options: ToastOptions = { duration: Infinity };
            toast.custom(t => makeFlashbarToast(t, newMessage), options);
            return { ...state, messages: [newMessage], nextMessageId };
        }

        case MESSAGES_REMOVE_MESSAGE: {
            // Current display logic dictates we only want to display one message at a time
            // This functionality could change, so we are filtering out the id instead of
            // clearing the array completely.
            toast.dismiss();
            return { ...state, messages: state.messages.filter(m => m.id != payload.id) };
        }

        default:
            return state;
    }
};

export const messagesReducer = persistReducer<MessagesReducer, tMessagesAction>(
    messagesPersistConfig,
    messages
);
