import { initialState } from './initialState';
import {
    PROPERTY_DETAILS_CLEAR_PROPERTY_DETAILS,
    PROPERTY_DETAILS_GET_PROPERTY_DETAILS,
    PROPERTY_DETAILS_GET_USER_UPLOADED_PROPERTY_DOCUMENTS,
    PROPERTY_DETAILS_SET_PROPERTY_DETAILS_LOADING,
    PROPERTY_DETAILS_SET_USER_UPLOADED_PROPERTY_DOCUMENTS_LOADING,
} from './action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { tPropertyDetailsAction, tPropertyDetailsReducerState } from './types';

const propertyDetailsPersistConfig = {
    key: 'propertyDetails',
    storage: storage,
    whitelist: [],
};

const propertyDetails = (state = initialState, action: tPropertyDetailsAction) => {
    switch (action.type) {
        case PROPERTY_DETAILS_CLEAR_PROPERTY_DETAILS: {
            return initialState;
        }
        case PROPERTY_DETAILS_GET_PROPERTY_DETAILS: {
            const attachments =
                action.payload.data.attachments && action.payload.data.attachments.length > 0
                    ? action.payload.data.attachments.filter(el => el.key === 'public')
                    : [];
            return {
                ...state,
                property: { ...action.payload.data, attachments },
                loading: action.payload.loading != null ? action.payload.loading : state.loading,
            };
        }
        case PROPERTY_DETAILS_SET_PROPERTY_DETAILS_LOADING: {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }
        case PROPERTY_DETAILS_GET_USER_UPLOADED_PROPERTY_DOCUMENTS: {
            return {
                ...state,
                user_uploaded_property_documents: action.payload.data || [],
                documents_loading:
                    action.payload.loading != null
                        ? action.payload.loading
                        : state.documents_loading,
            };
        }
        case PROPERTY_DETAILS_SET_USER_UPLOADED_PROPERTY_DOCUMENTS_LOADING: {
            return {
                ...state,
                documents_loading: action.payload.loading,
            };
        }
        default:
            return state;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const propertyDetailsReducer = persistReducer<
    tPropertyDetailsReducerState,
    tPropertyDetailsAction
>(propertyDetailsPersistConfig, propertyDetails);
