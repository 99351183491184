export enum Routes {
    MAIN = '/',
    CITY_RESULT = '/municipality/:slug',
    PROPERTY_SALES = '/municipality/:slug/property-sales',
    PROPERTY_LEDGER_RESULT = '/municipality/:slug/:property_id',
    RENT_LEVELING_SUMMARY = '/municipality/:slug/rent-leveling-summary',
    NOTIFICATION_SETTINGS = '/notification-settings',
    OTHER_USER = '/user',
    FRACTIONALIZATION = '/fractionalize',
    GOOGLE_REDIRECT = '/google-redirect',
    MUNICIPALITY_SELECT = '/municipality-select',
    VERIFY_EMAIL = '/verify-email',
    USER_MANAGEMENT = '/admin/user-management',
    PRODUCT_PERMISSIONS = '/admin/product-permissions',
    NOT_FOUND = '*',
}

export enum MenuNames {
    CITY_RESULT = 'ASSET REGISTRY',
    PROPERTY_SALES = 'PROPERTY SALES',
    RENT_LEVELING = 'RENT LEVELING',
}

export enum RedirectRoutes {
    COH_COMPLIANCE = '/municipality/:slug/coh-compliance',
}
