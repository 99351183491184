import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconCopy } from 'src/icons/Copy';
import { IconCheck } from 'src/icons/Check';
import { copyText } from 'src/helpers/copyText';
import {
    addressStringFormatter,
    truncateHash,
    makeArmadaExplorerUrl,
    formatMunicipalityWithState,
} from 'src/helpers';
import reNFTMark from 'src/assets/images/reNFTMark.svg';
import './PropertyHeader.scss';

interface Property {
    address: string;
    city_state: string;
    estate_type: string;
    token_id?: number;
    vacant: boolean | null;
    municipality: string;
    hash?: string;
    contract_address?: string;
    state?: string;
    zip?: string;
    smart_contract_uri?: string;
}

interface PropertyHeaderProps {
    property: Property;
    slug?: string;
}

interface CopyButtonProps {
    onClick: () => void;
}

const CopyButton: FC<CopyButtonProps> = ({ onClick }) => {
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        onClick();
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <button className="copy-button" onClick={handleClick}>
            {copied ? <IconCheck width={16} height={16} /> : <IconCopy width={16} height={16} />}
        </button>
    );
};

export const PropertyHeader: FC<PropertyHeaderProps> = ({ property, slug }) => {
    const navigate = useNavigate();

    const getStatusBadge = (vacant: boolean | null) => {
        if (vacant === true) return 'Vacant and Abandoned';
        return null;
    };

    const statusBadge = getStatusBadge(property.vacant);

    return (
        <div className="property-header">
            <div className="container">
                <div className="property-header__breadcrumb">
                    <span
                        className="breadcrumb-municipality"
                        onClick={() => navigate(`/municipality/${slug}`)}
                        role="button"
                        tabIndex={0}
                    >
                        {formatMunicipalityWithState(property.municipality, property.state)}
                    </span>
                    <span className="breadcrumb-separator">›</span>
                    <span className="breadcrumb-text">Property Ledger</span>
                </div>
                <div className="property-header__content">
                    <div className="property-header__info">
                        <h1 className="property-header__address">
                            {addressStringFormatter(
                                property.address,
                                property.municipality,
                                property.state,
                                property.zip
                            )}
                        </h1>
                        <div className="property-header__details">
                            <span className="property-type">{property.estate_type}</span>
                            {statusBadge && <span className="status-badge">{statusBadge}</span>}
                        </div>
                    </div>
                    <div className="property-header__blockchain-info">
                        <img src={reNFTMark} alt="reNFT Mark" className="renft-mark" />
                        <div className="blockchain-details">
                            {property.hash && (
                                <div className="info-item">
                                    <span className="label">Mint TX</span>
                                    <div className="value-container">
                                        <a
                                            href={makeArmadaExplorerUrl(property.hash, 'tx')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="value monospace"
                                        >
                                            {truncateHash(property.hash)}
                                        </a>
                                        <CopyButton
                                            onClick={() => property.hash && copyText(property.hash)}
                                        />
                                    </div>
                                </div>
                            )}
                            {property.contract_address && (
                                <div className="info-item">
                                    <span className="label">Contract</span>
                                    <div className="value-container">
                                        <a
                                            href={makeArmadaExplorerUrl(
                                                property.contract_address,
                                                'address'
                                            )}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="value monospace"
                                        >
                                            {truncateHash(property.contract_address)}
                                        </a>
                                        <CopyButton
                                            onClick={() =>
                                                property.contract_address &&
                                                copyText(property.contract_address)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="info-item">
                                <span className="label">Token ID</span>
                                {property.smart_contract_uri ? (
                                    <div className="value-container">
                                        <a
                                            href={property.smart_contract_uri}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="value monospace"
                                        >
                                            #{property.token_id}
                                        </a>
                                    </div>
                                ) : (
                                    <span className="value monospace">#{property.token_id}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
