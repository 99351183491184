import { tRentLevelingReducerState } from './types';

export const initialState: tRentLevelingReducerState = {
    loading: false,
    rentLeveledProperties: [],

    pagination: {
        pageIndex: 0,
        pageSize: 10,
    },
    query: {
        skip: 0,
        take: 50,
    },
};
